.container {
  display: flex;
  flex-direction: column;
  width: 188px;
  height: 161px;
  border-radius: 8px;
  background: var(--color-bg-secondary, #ECF1F4);
  overflow: hidden;
  padding: 15px;
  border: 1px solid var(--color-bg-secondary, #ECF1F4);
}

.active {
  border: 1px solid var(--color-typo-link);
}

.topRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.avatar {
  width: 52px;
  height: 52px;
  object-fit: contain;
  flex-shrink: 0;
}

.tagNameVertical {
  display: flex;
  padding: 0 4px;
  height: 20px;
  border-radius: 4px;
}

.verticalTitle {
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}

.teacherAvatar {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-right: 16px;
  font-size: 14px;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.userName {
  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 4px;
}

.userPosition {
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.6));
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-line;
}

.userEmail {
  color: var(--color-typo-link, #0078D2);
  font-size: 10px;
  font-weight: 400;
  line-height: 140%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

