.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 144px;
}

.title {
  margin-bottom: 72px;
  font-size: 32px;
  font-weight: 700;
  line-height: 150%;
}

.row {
  display: flex;
  column-gap: 16px;
}

.leftColumn {
  display: flex;
  width: 391px;
  flex-direction: column;
}

.team {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 500;
  line-height: 150%;
}

.post {
  font-size: 16px;
  line-height: 150%;
}

.email {
  margin-bottom: 40px;
  color: var(--color-typo-link, #0078D2);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
}

.rowResources {
  display: flex;
  margin-bottom: 8px;
}

.resources {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}

.mobile {
  color: var(--color-typo-link, #0078D2);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
}

.card {
  display: flex;
  flex-direction: column;
  width: 392px;
}

.imgAvatar {
  display: flex;
  margin-bottom: 16px;
  width: 52px;
  height: 52px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  object-fit: contain;
}

.name {
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 4px;
}

.position {
  white-space: pre-line;
  margin-bottom: 16px;
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.60));
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  min-height: 48px;
}

.cardEmail {
  color: rgba(0, 32, 51, 0.30);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
}



.iconEdit {
  cursor: pointer;
  margin-left: 10px;
}
