.container {
  display: flex;
  flex-direction: column;
  width: 488px;
  padding: 32px 20px 20px 20px;
  border-radius: 8px;
  background: var(--color-bg-default);
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 12px;
}

.info {
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 24px;
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #B8CAD5;
  margin-bottom: 20px;
}

.buttonRow {
  display: flex;
  justify-content: space-between;
}
