.container {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 16px;
}

.calendarWrapper {
  position: relative;
  padding: 20px;
  width: 292px;
  border-radius: 8px;
  border: 1px solid var(--color-bg-border, rgba(0, 65, 102, 0.20));
  margin-bottom: 32px;
}

.buttonIncrease {
  position: absolute;
  top: 20px;
  left: 240px;
  z-index: 1;
}

.centerPlaceHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 52px;
  height: 32px;
  width: 188px;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  cursor: default;
}

.buttonDecrease {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
}

.list {
  display: flex;
  flex-direction: column;
}

.delimiter {
  width: 100%;
  height: 1px;
  margin: 16px 0;
  background-color: var(--color-bg-border);
}
