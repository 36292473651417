.container {
  display: flex;
  flex-direction: column;
}

.date {
  color: var(--color-typo-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 4px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 8px;
  white-space: pre-line;
}

.btnReadMore {
  color: var(--color-typo-link, #0078D2);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-left: -12px;
}
