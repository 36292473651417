.container {
  display: flex;
  margin-bottom: 144px;
  padding: 48px 48px;
  border-radius: 16px;
  background:#ECF1F4;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  margin-left: 72px;
}

.topPart {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.title {
  font-size: 32px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 16px;
}

.description {
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  white-space: pre-line;
}

.bottomPart {
  display: flex;
  width: 595px;
  justify-content: flex-end;
}

.bottomLeft {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.bottomLeftTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 150%;
  color: #00203399;
  margin-bottom: 4px;
}

.bottomLeftDescription {
  display: flex;
  align-items: flex-end;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: #00203399;
  white-space: pre-line;
}
