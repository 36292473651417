.container {
  display: flex;
  flex-direction: column;
  height: 504px;
  padding: 72px 48px;
  margin-top: 40px;
  margin-bottom: 72px;
  background-image: url("../../../img/mainPage/heroBanner.png");
  border-radius: 16px;
}

.textContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  row-gap: 16px;
}

.title {
  font-size: 48px;
  font-weight: 600;
  line-height: 120%;
  white-space: pre-line;
  color: #FAFAFA;
}

.description {
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  white-space: pre-line;
  color: #FAFAFA;
}

.button {
  color: #0071B2;
  background-color: #FFFFFF;
}

.bottomDesc {
  display: flex;
  align-items: center;
  width: 539px;
  bottom: 0;
  border-radius: 16px;
  background: var(--bg-secondary, #ECF1F4);
  padding: 16px;
  cursor: pointer;
}
.bottomDesc .imgWrap {
  padding: 6px;
  background: var(--bg-default, #FFF);
  border-radius: 8px;
  margin-right: 16px;
}
.bottomDesc .bottomText {
  margin-right: 20px;
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}

