.container {
  display: flex;
}

.dropBox {
  position: absolute;
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: flex-start;
  top: 46px;
  left: -1px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--color-control-bg-border-default, rgba(0, 66, 105, 0.28));
  background: var(--color-bg-default, #FFF);
  box-shadow: 0 8px 24px 0 rgba(0, 32, 51, 0.12), 0 4px 4px 0 rgba(0, 32, 51, 0.04);
}

.textField {
  display: flex;
  width: 100%;
  flex-grow: 0;
  margin-bottom: 20px;
}

.buttonRow {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  justify-content: flex-start;
  column-gap: 20px;
}

.footerRow {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
