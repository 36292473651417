.container {
  display: flex;
  flex-direction: column;
  width: 386px;
}

.row {
  display: flex;
}

.marker {
  display: flex;
  flex-shrink: 0;
  width: 2px;
  margin-right: 6px;
}

.markerActive {
  background: var(--color-typo-link);
}

.title {
  display: flex;
  width: 100%;
  margin: 10px 0;
  padding-right: 8px;
  font-size: 14px;
  line-height: 120%;
  cursor: pointer;
}

.noContentTitle {
  color: var(--color-typo-secondary);
}

.titleActive {
  color: var(--color-typo-link);
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  background: var(--color-bg-border);
}
