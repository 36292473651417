.container {
  position: relative;
  display: flex;
  cursor: pointer;
  z-index: 1000;
}

.longContainer {
  height: 200px;
  background-color: #32B4FF;
}

.tabItem {
  align-items: center;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 120%;
  color: var(--color-typo-secondary);
}

.selected {
  color: var(--color-typo-primary);
}

.disabled {
  color: var(--color-control-typo-disable);
  cursor: not-allowed;
}

.ico {
  margin-top: 2px;
  margin-left: 4px;
}

.rotateIco {
  transform: rotate(180deg);
}

.childrenContainer {
  position: absolute;
  top: 35px;
  left: 0;
  display: flex;
  flex-direction: column;
  min-width: 240px;
  padding: 8px 0;
  width: 100%;

  border-radius: 4px;
  border: 1px solid var(--color-bg-border, rgba(0, 65, 102, 0.20));
  background: var(--color-bg-default, #FFF);
  box-shadow: 0 8px 24px 0 rgba(0, 32, 51, 0.12), 0 4px 4px 0 rgba(0, 32, 51, 0.04);
}
