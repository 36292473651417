.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 28px;
  margin-top: -16px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 32px;
}

.row {
  display: flex;
  margin-bottom: 20px;
}

.ico {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.textContainer {
  display: flex;
}

.rowTextContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.topText {
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
}

.bottomText {
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.60));
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.linkText {
  color: var(--color-typo-link, #0078D2);
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  cursor: pointer;
}

.separator {
  display: flex;
  margin-top: 3px;
  padding: 0 8px;
}


