.container {
  display: flex;
  width: 697px;
  margin-top: 72px;
  margin-bottom: 40px;
  justify-content: space-between;
  align-items: center;
}

.total {
  color: rgba(0, 32, 51, 0.35);
  font-size: 16px;
  font-weight: 400;
}
