.ComboboxExampleRenderItem-Item {
  --text-color: var(--color-control-typo-default);
  position: relative;
  padding: var(--space-xs) var(--space-l);
  color: var(--text-color);
}

.ComboboxExampleRenderItem-Item_hovered {
       background-color: var(--color-control-bg-clear-hover);
     }

.ComboboxExampleRenderItem-Item_active {
       --text-color: var(--color-control-typo-secondary);
     }

.ComboboxExampleRenderItem-Item_disabled {
       --text-color: var(--color-control-typo-disable);
     }

.ComboboxExampleRenderItem-Item::before {
       content: '';
       position: absolute;
       top: 0;
       left: 0;
       width: 2px;
       height: 100%;
       transition: background-color 0.2s ease-in;
     }

.ComboboxExampleRenderItem-Item_active::before {
       background-color: var(--text-color);
     }

.ComboboxExampleRenderItem-Badge {
     margin-left: var(--space-m);
   }

.canary--SelectDropdown {
  height: 182px;
  z-index: 3;
}

.canary--SelectDropdown-ScrollContainer {
  max-height: 182px;
  height: 182px;
}

.canary--Select-ClearIndicator {
  /*display: none !important;*/
}

.canary--Select-Input {
  cursor: pointer;
}


