.container {
  display: flex;
  justify-content: center;
  margin-top: 72px;
  scroll-margin-top: 60px;
  margin-bottom: 40px;
}

.contentContainer {
  position: relative;
  display: flex;
  width: 1270px;
  padding: 48px 32px;
  flex-direction: column;
  column-gap: 24px;
  border-radius: 16px;
  background: var(--bg-secondary, #ECF1F4);
}

.title {
  margin-bottom: 40px;
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 48px */
  display: flex;
  align-items: center;
}

.infoImg {
  margin-right: 16px;
}
.list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 32px;
}
