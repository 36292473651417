.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 144px;
}

.title {
  margin-bottom: 48px;
  font-size: 32px;
  font-weight: 700;
  line-height: 150%;
}

.cardsContainer {
  display: flex;
}

.personCard {
  display: flex;
  width: 392px;
  flex-direction: column;
  margin-right: 16px;
}

.avatar {
  margin-bottom: 16px;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  object-fit: contain;
}

.name {
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
}

.position {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  white-space: pre-line;
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.60));
}

.email {
  color: var(--color-typo-ghost, rgba(0, 32, 51, 0.30));
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
}

.email:hover {
  color: var(--color-typo-link, #0078D2);
}

.teamCard {
  display: flex;
  flex-direction: column;
}

.img {
  width: 52px;
  margin-bottom: 16px;
}

.btn {
  margin-top: -8px;
  color: var(--color-typo-link, #0078D2);
  margin-left: -16px;
}

.iconEdit {
  cursor: pointer;
  margin-left: 10px;
}
