.sidebarFilter {
  width: 452px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: var(--bg-secondary, #ECF1F4);
  row-gap: 12px;
}

.sidebarTitle {
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  padding: 14px 12px;
  background: #fff;
}

.sidebarItem {
  background: #fff;
  padding: 8px 16px;
  margin-bottom: 8px;
}
.sidebarItem .itemTitle {
  color: var(--typo-secondary, rgba(0, 32, 51, 0.6));
  /* L Medium */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
.sidebarItem .itemTitle span {
  margin-right: 8px;
}
.sidebarItem .itemTitle span.IconTrash {
  margin-left: 16px;
  cursor: pointer;
}
.sidebarItem .itemText {
  color: var(--control-default-typo, #002033);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.bottomButtons {
  background: #fff;
  border-top: 1px solid #C7D4DB;
  padding: 8px 12px;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 9999;
}
.bottomButtons button {
  margin-left: 10px;
}

.fullWidth {
  width: 412px;
}

.itemBlock {
  background: #fff;
}

.moreButton {
  margin-bottom: 100px;
}

