.container,
.container_multiLineTruncate {
  display: flex;
  align-items: flex-start;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 150%;
}

.value {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.value_multiLineTruncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

.alignItemsCenter {
  align-items: center;
}
