.container {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  width: 100%;
  padding: 32px;
  border-radius: 8px;
  background: var(--color-bg-secondary, #ECF1F4);
}

.ico {
  width: 48px;
  height: 48px;
  margin: 16px;
}

.title {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  white-space: pre-line;
}

.btnRow {
  display: flex;
  justify-content: space-between;
}
