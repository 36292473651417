.container {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  width: 100%;
  padding: 32px;
  border-radius: 8px;
  background: var(--color-bg-secondary, #ECF1F4);
}

.title {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  white-space: pre-line;
}

.subtitle {
  margin-bottom: 42px;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  white-space: pre-line;
}
