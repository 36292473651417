.filterItem {
  background: #fff;
  padding: 24px;
  .title {
    color: rgba(0, 0, 0, 0.80);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 27px */
    padding-bottom: 20px;
    border-bottom: 1px solid #DEE4E8;
    display: flex;
    align-items: center;
    .clear {
      margin-left: 20px;
    }
  }
  .search {
    width: 100%;
    margin-bottom: 8px;
  }
  .checkboxGroup {
    border-radius: 4px;
    border: 1px solid var(--control-default-bg-border, rgba(0, 66, 105, 0.28));
    background: var(--control-default-bg, #FFF);
    padding: 9px 12px;
  }
}


.clear {
  color: var(--typo-link, #0078D2);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  cursor: pointer;
}
