.container {
  display: flex;
  flex-direction: column;
  width: 327px;
  overflow: hidden;
}

.title {
  margin-bottom: 8px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  white-space: pre-line;
}

.description {
  margin-bottom: 20px;
  line-height: 140%;
  white-space: pre-line;
}

.buttonContainer {
  margin-bottom: 24px;
}
.button {
  padding: 0;
  border: none;
}
