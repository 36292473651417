.filterRow {
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    button {
      margin-right: 16px;
    }
  }
}

.sidebarFilter {
  width: 640px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: var(--bg-secondary, #ECF1F4);
  row-gap: 12px;
  .sidebarTitle {
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.80);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    padding: 14px 12px;
    background: #fff;
  }
  .fitlerItem {
    padding: 20px 24px;
  }
}

.tagsRow {
  display: flex;
  flex-wrap: wrap;
  margin-top:12px;
  > * {
    margin-right: 4px;
    margin-bottom: 4px;
  }
}
.tagFlat {
  background: none;
}

.tagFlat :global  span{
  color: var(--control-ghost-typo, rgba(0, 57, 92));
}
