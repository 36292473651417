.container {
  display: flex;
  flex-direction: column;
}

.teamGroupContainer {
  /*margin-top: 32px;*/
  display: flex;
  /*// flex-direction: column;*/
  padding-bottom: 72px;
  border-bottom: 1px solid var(--bg-border, rgba(0, 65, 102, 0.20));
  margin-top: 72px;
}

.groupTitle {
  color: var(--typo-primary, #002033);
  /* 2XL Medium */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */
  width: 390px;
  min-width: 390px;
}

.groupMembersContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 40px;
}

.button {
  display: block;
  min-width: 100%;
}

.iconEdit {
  cursor: pointer;
}
