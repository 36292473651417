.container {
  display: flex;
  width: 391px;
  flex-direction: column;
  flex-shrink: 0;
  position: sticky;
  top: 100px;
  align-self: flex-start;
}

.buttons {
  display: flex;
  margin-bottom: 50px;
}
.buttons button {
  margin-right: 8px;
}
