.layout {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  align-items: center;
  padding: 0 var(--space-xl);
  background-color: var(--color-bg-default);
  border-bottom: 1px solid var(--color-bg-border);
  z-index: 999;
}

.tabsWrapper {
  display: flex;
  width: 100%;
  min-height: 40px;
  height: 100%;
  margin-left: 36px;
  align-items: flex-end;
}

.btnWrapper {
  position: absolute;
  right: 100px;
}

.dqBarWrapper {
  display: flex;
  min-height: 40px;
  height: 100%;
  margin-left: 36px;
  align-items: flex-end;
  position: absolute;
  right: 140px;
}
.manuals {
  z-index: 9999;
}

.hiddenItemsButton {
  margin-top: -16px;
}

.tooltip {
  z-index: 9999;
}
