.container {
  display: flex;
  flex-shrink: 0;
  width: 427px;
  opacity: 1;
  transition: width 0.3s ease, opacity 0.3s ease;
}

.hideContainer {
  width: 0;
  opacity: 0;
  transition: width 0.3s ease, opacity 0.3s ease;
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 12px 20px 12px 0;
  border-right: 1px solid var(--color-bg-border);
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
}

.columnHeader {
  display: flex;
  flex-grow: 0;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 12px;
}

.textField {
  display: flex;
  width: 100%;
  flex-grow: 0;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-bg-border);
}
