.MixFocus {
  --mix-focus-color-one: none;
  --mix-focus-color-two: none;
  --mix-focus-animation: none;
}

.TabsLine {
  pointer-events: none;
}

.TabsMoreItems-Popover {
  z-index: 1000;
}
