.container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 1360px;
  align-items: center;
}

.fixedContainer {
  position: fixed;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  z-index: 50;
}

.title {
  margin-top: 29px;
}

.infoContainer {
  display: flex;
}


