.container {
  display: flex;
  height: 130px;
  padding: 27px 80px;
  background-color: var(--color-bg-default, #22272b);
}

.logoContainer {
  display: flex;
  flex-shrink: 0;
  width: 138px;
  height: 48px;
}

.leftColumn {
  display: flex;
  flex-grow: 1;
}

.leftPart {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 48px;
}

.logo {
  cursor: pointer;
}

.copyright {
  line-height: 120%;
  white-space: pre-line;
}

.textContainer {
  display: flex;
  flex-grow: 1;
  margin: 0 32px;
  justify-content: flex-end;
}

.questionsContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
}

.rightColumn {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 100px;
}

.questions {
  display: flex;
  white-space: pre-line;
  line-height: 120%;
  margin-bottom: 13px;
}

.versionContainer {
  display: flex;
  align-items: flex-end;
  color: var(--color-typo-ghost);
  text-align: right;
  margin-right: 48px;
  line-height: 120%;
}

.button {
  padding: 0 12px;
}
