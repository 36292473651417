.wrapper {
	display: flex;
  width: 100%;
	padding: 8px 0;
	align-items: center;
	justify-content: center;
}

.wrapper:hover {
	background-color: rgba(0, 66, 105, 0.05);
}

.columnHeader {
	position: relative;
	display: flex;
	font-size: 12px;
	line-height: 150%;
	width: 100%;
	padding: 0 12px;
	overflow: hidden;
}
