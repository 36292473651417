.wrapper {
	display: flex;
	margin: 0 4px;
	align-items: center;
	justify-content: center;
	position: relative;
}

.icon {
	width: 24px;
	height: 24px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon:hover {
	background-color: rgba(0, 66, 105, 0.07);
}
