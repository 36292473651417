.tr {
  padding: 20px 0;
  border-top: 1px solid var(--control-default-bg-border, rgba(0, 66, 105, 0.28));
  display: flex;
}
.trTitle {
  width: 553px;
  margin-right: 16px;
}
.trCurrent, .trFuture {
  width: 270px;
  margin-top: 32px;
}
.trCurrent {
  margin-right: 16px;
}

.title {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  margin-bottom: 8px;
}
.description {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
