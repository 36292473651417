.wrapper {
    padding: 20px;
    display: flex;
    max-height: 570px;
    width: 30%;
    min-height: 100px;
    flex-direction: column;
}

.action__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 20px;
}