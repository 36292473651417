.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 452px;
  height: 100%;
  border-right: 1px solid var(--color-bg-border);
  transition: width 0.5s ease-in-out;
  overflow: hidden;
}

.closeContainer {
  width: 0;
  border:none;
}

.columnHeader {
  display: flex;
  width: 100%;
}

.title {
  display: flex;
  flex-grow: 1;
  font-weight: 700;
  overflow: hidden;
  opacity: 1;
}

.hideTitle {
  width: 0;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.noTitle {
  width: 0;
  overflow: hidden;
}

.verticalTitle {
  position: absolute;
  top: 50%;
  left: 0;
  color: #667985;
  transform: rotate(-90deg);
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.hideVerticalTitle {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.childrenContainer {
  display: flex;
  height: 100%;
  margin: 0 20px 0 20px;
  width: calc(100% + 10px);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  overflow: hidden;
  max-width: 408px;
}

.hideChildren {
  opacity: 0;
}
