.container {
  width: 1126px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contactsHeader {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  padding: 0 32px;
  height: 70px;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--bg-border, rgba(0, 65, 102, 0.2));
}

.contactsBody {
  display: flex;
  width: 100%;
  height: 480px;
}
.contactsBody .rightPart, .contactsBody .leftPart {
  width: 50%;
  padding: 20px 32px;
  box-sizing: border-box;
}
.contactsBody .leftPart {
  width: 50%;
  border-right: 1px solid var(--bg-border, rgba(0, 65, 102, 0.2));
  overflow-y: auto;
  overflow-x: hidden;
}
.contactsBody .headRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--typo-primary, #002033);
  /* L Medium */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}
.contactsBody .contactsList {
  display: flex;
  margin-top: 28px;
  flex-direction: column;
}
.contactsBody .contactsList .contact {
  display: flex;
  align-items: center;
  padding: 5px;
  margin-bottom: 10px;
}
.contactsBody .contactsList .contact.active {
  background: var(--control-clear-bg-hover, rgba(0, 66, 105, 0.05));
}
.contactsBody .contactsList .contact .avatar {
  min-width: 52px;
  min-height: 52px;
  margin-left: 20px;
  margin-right: 8px;
}
.contactsBody .contactsList .contact .userInfo .userName {
  color: var(--control-default-typo, #002033);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 8px;
}
.contactsBody .contactsList .contact .userInfo .userPosition {
  overflow: hidden;
  color: var(--typo-ghost, rgba(0, 32, 51, 0.3));
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: 331px;
}
.contactsBody .contactsList .contact .userInfo .userEmail a {
  color: var(--typo-ghost, rgba(0, 32, 51, 0.3));
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.contactsBody .contactsList .contact .icons {
  cursor: pointer;
  display: flex;
}
.contactsBody .contactsList .contact .icons span {
  margin-left: 20px;
}

.rightPart .notSelected {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.rightPart .notSelected .rightTitle {
  color: var(--typo-primary, #002033);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 24px */
  margin-bottom: 8px;
  margin-top: 100px;
}
.rightPart .notSelected .rightDesc {
  color: var(--typo-secondary, rgba(0, 32, 51, 0.6));
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
}
.rightPart .formTitle {
  margin-bottom: 20px;
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}

.fullWidth {
  margin-top: 24px;
  width: 100%;
}

.saveButton {
  margin-top: 20px;
}

.drag {
  min-width: 15px;
  min-height: 15px;
}

.contactsFooter {
  height: 88px;
  align-items: center;
  justify-content: end;
  display: flex;
  width: 100%;
  padding: 0 32px;
  width: 100%;
  border-top: 1px solid var(--bg-border, rgba(0, 65, 102, 0.2));
}
.contactsFooter button {
  margin-left: 12px;
}

