.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.userNameContainer {
  display: flex;
}

.userName {
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 4px;
}

.userPosition {
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.60));
  font-size: 12px;
  font-weight: 400;
  line-height: 110%;
  margin-bottom: 20px;
}

.dateTime {
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.60));
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 12px;
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  margin: 40px 0;
  background: var(--color-bg-border, rgba(0, 65, 102, 0.20));
}

.btnContainer {
  position: absolute;
  top: 0;
  right: 0;
}
