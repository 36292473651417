.container {
  display: flex;
  align-items: center;
  padding-top: 4px;
}

.btnContainer {
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  margin-right: 32px;
}

.logoBtn {
  height: 32px;
  width: 32px;
}

.logo {
  cursor: pointer;
}

.sideBar {
  display: flex;
  position: absolute;
  padding-top: 32px;
  top: 60px;
  left: -315px;
  width: 315px;
  box-shadow: 0 8px 24px 0 rgba(0, 32, 51, 0.12), 0 4px 4px 0 rgba(0, 32, 51, 0.04);
  transition: left 0.3s ease;
  background: #fff;
}

.sidebarOpen {
  left: 0;
  transition: left 0.3s ease;
}

.logoWrapper {
  display: flex;
  align-items: center;
  width: 180px;
}
