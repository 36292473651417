.container {
  display: flex;
  width: 100%;
  margin-bottom: 72px;
  flex-direction: column;
}

.title {
  margin-bottom: 24px;
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.60));
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
}

.tagsContainer {
  display: flex;
  width: 100%;
  column-gap: 8px;
  row-gap: 12px;
  flex-wrap: wrap;
}

.delimiter {
  display: flex;
  width: 100%;
  margin: 16px 0;
  height: 1px;
  background-color:var(--color-bg-border);
}
