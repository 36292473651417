.sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 60px;
  right: 0;
  height: calc(100vh - 60px);
  box-shadow: 0 8px 24px 0 rgba(0, 32, 51, 0.12), 0 4px 4px 0 rgba(0, 32, 51, 0.04);
  background-color: var(--color-bg-default);
  z-index: 999;
}

.children {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.secondaryBackground {
  background-color: var(--color-bg-secondary);
}

.closeButton {
  position: absolute;
  top: 4px;
  left: -28px;
  background-color: #ccd9e1
}

.closeButton:hover {
  background-color: #bfd0da !important;
}

.enter {
  transform:translateX(100%);
}
.enterActive {
  transform:translateX(0);
  transition:transform 0.3s ease-in-out;
}
.exit {
  opacity: 1;
  transform:translateX(0);
}
.exitActive {
  transform:translateX(100%);
  transition: transform 0.3s ease-in-out;
}
