.container {
  display: flex;
  flex-direction: column;
  width: 559px;
  min-height: 78px;
}

.title {
  color: #0078D2;

  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  margin-bottom: 16px;
  cursor:pointer;
}

.description {
  white-space: pre-line;
  color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */

}

.buttonContainer {
  display: flex;
  height: 100%;
  align-items: flex-start;
}

.buttonsDetails {
  /*margin-left: -20px;*/
  color: var(--color-bg-link);
}
