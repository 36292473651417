.container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
  max-width: 400px;
}

.row:hover {
  background: rgba(0, 66, 105, 0.05);
}

.verticalDelimiter {
  width: 2px;
  height: 100%;
  flex-shrink: 0;
}

.verticalDelimiterSelected {
  background-color: var(--color-typo-link);
}

.processNumber {
  padding-left: 8px;
  margin-right: 8px;
  color: var(--color-control-typo-placeholder);
}

.processSelected {
  color: var(--color-typo-link) !important;
}

.processTitle {
  flex-shrink: 0;
  width: 300px;
  padding: 10px 0;
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #B8CAD5;
}

.btnOpenCloseContainer {
  position: relative;
  display: flex;
  margin-left: 12px;
}

.btnClose {
  padding: 0 !important;
  width: 24px !important;
  height: 24px !important;
  z-index: 1;
}

.ico {
  position: absolute;
  top: 5px;
  left: -7px;
  margin-left: 12px;
  width: 16px;
  height: 16px;
  color: rgba(0, 57, 92, 0.8);
  cursor: pointer;
}

