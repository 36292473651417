.container {
  position: relative;
  display: flex;
  margin-bottom: 24px;
}

.indContainer {
  display: flex;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  background-color: #56B9F2;
  border-radius: 50%;
  z-index: 1;
}

.ind {
  color: #FFFFFF;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
}

.title {
  font-size: 14px;
  line-height: 16px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.connector {
  position: absolute;
  display: flex;
  width: 4px;
  top: 0;
  left: 6px;
  background-color: #DEE4E8;
}
