body {
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 !important;
}

* {
  box-sizing: border-box;
}

:root {
  --widthCard: 256px;
  --maxWidthCard: 256px;
  --lineWidth: 1px;
  --contentMinWidth: 1206px
}
.Modal {
  z-index: 999;
}
.SelectDropdown {
  z-index: 9999;
}

.MixPopoverAnimate_animate_enterActive, .MixPopoverAnimate_animate_enterDone, .MixPopoverAnimate_animate_entered, .MixPopoverAnimate_animate_entering  {
  transform: none !important;
}

.without-close .canary--Select-ClearIndicator {
  display: none;
}
