.container {
  display: flex;
  width: 391px;
  padding: 20px 24px;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid rgba(0, 65, 102, 0.20);
  background: #FFF;
}

.title {
  height: 48px;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 8px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.description {
  height: 42px;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 8px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tagList {
  height: 21px;
  display: flex;
  column-gap: 12px;
  overflow: hidden;
  margin-bottom: 21px;
}

.tag {
  font-size: 14px;
  line-height: 150%;
}
