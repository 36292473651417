.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 72px;
  padding-bottom: 144px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 72px;
}

.userName {
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 16px;
}

.textField {
  display: flex;
  width: 100%;
  height: 138px;
  margin-bottom: 25px;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 64px;
}
