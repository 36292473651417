.container {
  display: flex;
  flex-shrink: 0;
  width: 300px;
  z-index: 101;
}

.combobox {
  z-index: 101;

}
.combobox .canary--Select-ClearIndicator {
  display: none;
}
