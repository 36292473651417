.container {
  display: flex;
  padding: 0;
  border: none;
  outline: none;
  background-color: inherit;
  cursor: pointer;
  color: var(--color-typo-link);
  font-size: 16px;
  line-height: 19px;
}

.container:hover {
  color: var(--color-typo-link-hover);
}

.container:disabled {
  color: var(--color-typo-ghost);
  cursor: default;
}

.linkTitle {
  display: flex;
  justify-content: center;
}
