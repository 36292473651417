.container {
  display: flex;
  flex-direction: column;
}

.content {
  height: calc(100vh - 286px);
  padding: 12px 20px;
  background-color: var(--color-bg-secondary);
}
