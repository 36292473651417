.container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.emptyDocumentsContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.emptyDocumentsTitle {
  width: 270px;
}

.title {
  display: inline;
}

.continueTitle {
  display: inline;
  cursor: pointer;
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #B8CAD5;
  margin: 19px 0 16px 0;
}

.loaderContainer {
  width: 100%;
  height: 65vh;
}
