.container {
  display: flex;
  width: 391px;
  flex-direction: column;
  flex-shrink: 0;
}

.growWrapper {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
}
