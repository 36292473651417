.container {
  display: flex;
  flex-direction: column;
  width: 420px;
  padding: 20px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
}

.delimiter {
  display: flex;
  height: 1px;
  background-color: var(--color-bg-border);
}

.textField {
  display: flex;
  width: 100%;
  flex-grow: 0;
  margin-top: 20px;
  margin-bottom: 12px;
}
