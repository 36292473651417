.container {
  display: flex;
  column-gap: 8px;
  margin-bottom: 12px;
}

.comboBox {
  max-width: 200px;
}

.buttonsGroupContainer {
  display: flex;
  margin-left: 20px;
  justify-content: flex-end;
  column-gap: 12px;
}
