.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 12px;
  overflow: hidden;
}

.topRow {
  display: flex;
  padding: 0 20px;
}

.title {
  display: flex;
  flex-shrink: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 12px;
}

.btn {
  margin-right: 8px;
}

.wrapperWithoutScroll {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.cardsView {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-right: 8px;
}

.tableView {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  overflow: hidden;
}

.tableWrapper {
  display: flex;
  overflow: hidden;
}

.btnContainer {
  display: flex;
  flex-grow: 1;
  justify-content: right;
}

.paginationWrapper {
  padding: 12px 10px;
}

