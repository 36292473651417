.container {
  display: flex;
  overflow: hidden;
}

.tableContainer {
  display: flex;
  max-height: calc(100vh - 310px) !important;
  overflow: hidden;
}

.tableWrapper {
  width: calc(100vw - 40px) !important;
  border-radius: 4px;
  border: 1px solid var(--color-control-bg-border-default);;
  background-color: var(--color-bg-default);
  overflow-y: auto;
}
