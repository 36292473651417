.container {
  display: flex;
  width: 145px;
  flex-direction: column;
}

.textInfoContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.indTitle {
  color: var(--typo-brand, #0071B2);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
}
.subTitle {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-line;
  color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.shortDescriptionTitle {
  -webkit-line-clamp: 3 !important;
  color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.descriptionTitle {
  color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
