.container {
  display: flex;
}

.dropBox {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 46px;
  left: -1px;
  padding: 4px 0;
  border-radius: 4px;
  border: 1px solid var(--color-control-bg-border-default, rgba(0, 66, 105, 0.28));
  background: var(--color-bg-default, #FFF);
  box-shadow: 0 8px 24px 0 rgba(0, 32, 51, 0.12), 0 4px 4px 0 rgba(0, 32, 51, 0.04);
}

.row {
  display: flex;
  padding: 4px 0;
  width: 100%;
  align-items: center;
}

.row:hover {
  background: rgba(0, 66, 105, 0.05);
  color: var(--color-typo-link);
}

.ico {
  margin-left: 8px;
}

.title {
  margin: 0 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
}

.row:hover .title {
  color: var(--color-typo-link);
}

.delimiter {
  display: flex;
  width: 100%;
  margin: 4px 0;
  height: 1px;
  background-color: var(--color-control-bg-border-default);
}
