.container {
  display: flex;
  margin-top: 60px;
  width: 100%;
  min-width: 1360px;
  height: 244px;
  background: var(--color-bg-secondary, #ECF1F4);
  justify-content: center;
}

.bannerContent {
  width: var(--contentMinWidth);
  display: flex;
  justify-content: space-between;
}

.leftColumn {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 32px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 16px;
}

.subTitle {
  white-space: pre-line;
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
}

.img {
  width: 287px;
  height: 244px;
}
