.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
}

.imgContainer {
  display: flex;
  margin-bottom: 16px;
  width: 100%;
  height: 192px;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
}

.subTitle {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.bottom {
  display: flex;
  justify-content: space-between;
}

.btnSelector {
  display: flex;
}

.button {
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(0, 66, 105, 0.07);
  border: none;
  outline: none;
  cursor: pointer;
}

.info {
  display: flex;
  margin: 0 24px;
  color: var(--color-typo-primary, #002033);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  align-items: center;
}

.btnReadMore {
  color: var(--color-typo-link, #0078D2);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-left: -12px;
}

.item {

}
