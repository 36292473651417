.container {
  display: flex;
  width: 100vw;
  height: 280px;
  justify-content: center;
  margin-bottom: 40px;
}

.contentContainer {
  position: relative;
  display: flex;
  width: var(--contentMinWidth);
  flex-direction: column;
  white-space: pre-line;
}

.breadcrumbs {
  margin-top: 24px;
  margin-bottom: 16px;
}

.title {
  margin-bottom: 16px;
  line-height: 108px;
}

.description {
  padding-left: 6px;
}
