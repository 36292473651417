.container {
  display: flex;
  width: var(--contentMinWidth);
  flex-direction: column;
}

.sectionHeader {
  display: flex;
  align-items: center;
  padding-right: 12px;
  background-color: #DCE5EA;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
}

.openBorders {
  border-radius: 6px 6px 0 0;
  border-bottom: none;
}

.titleRow {
  display: flex;
  margin: 12px 0;
  width: 100%;
  align-items: center;
}

.title {
  display: flex;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
}

.icoContainer {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  color: rgba(0, 57, 92, 0.80);
}

.icoTitle {
  margin-right: 10px;
  color: #002033;
  opacity: 60%;
}

.openPart {
  display: flex;
  background-color: #ECF1F4;
  border-radius: 0 0 6px 6px;
  border-top: none;
  overflow: hidden;
}

.colorSide {
  display: flex;
  width: 12px;
  height: 12px;
  margin-left: 16px;
  margin-right: 8px;
  background-color: #0078D2;
  flex-shrink: 0;
  border-radius: 50%;
}
