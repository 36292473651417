.container {
  display: flex;
  column-gap: 32px;
  margin-bottom: 24px;
}

.switch {
  display: flex;
  align-items: center;
}

.title {
  font-size: 14px;
  margin-right: 8px;
  color: var(--color-control-typo-ghost);
}
.active {
  color: rgb(0, 120, 210);
}
