.container {
  display: flex;
  flex-direction: column;
  width: 392px;
  flex-shrink: 0;
}

.title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 8px;
}

.description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  height: 40px;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 8px;
}

.type {
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 20px;
  overflow: hidden;
}

.buttonRow {
  display: flex;
  column-gap: 20px;
}
