.container {
  display: flex;
  flex-direction: column;
  min-width: 1366px;
}

.pageWrapper {
  min-height: calc(100vh - 130px);
}
.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.snackBarContainer {
  position: fixed;
  bottom: 60px;
  right: 60px;
}
.dump {
  margin-top: 90px;
}
