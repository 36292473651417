.container {
  display: flex;
  width: 100%;
  flex-direction: column;

}

.topRow {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
}

.type {
  margin-right: 16px;
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.60));
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.tagsContainer {
  display: flex;
  width: 100%;
  gap: 8px;
}

.title {
  white-space: pre-line;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 16px;
}

.date {
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.60));
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 40px;
}
