.wrapper {
  padding: 12px 20px;
  height: calc(100vh - 275px);
  overflow: hidden;
  background: var(--color-bg-secondary, #ECF1F4);
}

.container {
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border: 1px solid #CCD9E0;
  border-radius: 4px;
  row-gap: 24px;
  background-color: var(--color-bg-default);
  overflow: hidden;
}

.splitRow {
  display: flex;
  width: 100%;
  column-gap: 16px;
}

.splitRowPart {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.sectionTitle {
  margin-bottom: 8px;
}

.titleName {
  margin-bottom: 4px;
}

.titlePosition {
  width: 80%;
  margin-bottom: 4px;
  color: var(--color-typo-secondary);
  line-height: 150%;
}

.linkToEmail {
  font-size: 12px;
  margin-bottom: 4px;
  color: var(--color-typo-secondary);
}

.row {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.description {
  display: flex;
  white-space: pre-line;
}

.processPictureContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #0078d2;
  max-height: 200px;
}

.img {
  display: flex;
  justify-content: center;
  object-fit: contain;
}
