.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.row {
  display: flex;
  align-items: center;
}


.title {
  color: var(--color-typo-primary);
  /* M */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.disabled {
  color: var(--color-control-typo-disable);
  cursor: not-allowed;
}

.disabled:hover {
  color: var(--color-control-typo-disable);
}

.ico {
  /*margin-top: 13px;*/
  /*margin-left: 4px;*/
  position: absolute;
  cursor: pointer;
  right: 20px;
}

.rotateIco {
  transform: rotate(180deg);
}

.activeSign {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 2px;
  height: 100%;
  background-color: var(--color-typo-link);
}
