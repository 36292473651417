.container {
  display: flex;
  margin-bottom: 12px;
}
.textField {
  display: flex;
  width: 100%;
  flex-grow: 0;
}

.verticalDelimiter {
  display: flex;
  width: 1px;
  border-left: 1px solid var(--color-bg-border);
  margin: 0 12px;
}
