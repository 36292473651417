.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 600;
  line-height: 150%;
}

.description {
  margin-bottom: 48px;
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  white-space: pre-line;
}

.cardsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid var(--color-bg-border, rgba(0, 65, 102, 0.20));
  overflow: hidden;
  height: 442px;
}

.cardTop {
  display: flex;
  flex-direction: column;
  padding: 0 32px;
}

.cardTitle {
  margin-top: 44px;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
}

.cardDescription {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.cardBtn {
  margin-left: -16px;
  color: #0078D2;
}

.cardBottom {
  display: flex;
  background: var(--color-bg-secondary, #ECF1F4);
}

.bottomRightColumn {
  display: flex;
  flex-direction: column;
  margin-left: 99px;
  margin-top: 62px;
}

.amountTitle {
  font-size: 32px;
  font-weight: 700;
  line-height: 150%;
  color: #0078D2;
}

.amountDescription {
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}

.img {
  width: 264px;
  height: 220px;
  overflow: hidden;
  object-fit: cover;
}

