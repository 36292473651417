.container {
  display: flex;
  width: var(--contentMinWidth);
  margin-bottom: 36px;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-bottom: 8px;
}

.stickyHeaders {
  width: 100%;
  position: sticky;
  top: 156px;
  background-color: var(--color-bg-default);
  z-index: 49;
}

.scrollPart {
  display: flex;
  width: 100%;
}
.manageGroups {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: var(--consta-spaces-2xs, 4px);

  border: 1px solid var(--bg-border, rgba(0, 65, 102, 0.20));
  background: var(--bg-default, #FFF);
  width: 100%;
  /*margin-top: 20px;*/
}
.manageGroups button {
  margin-left: 16px;
}
