.container {
  display: flex;
  flex-direction: column;
  padding: 20px 0 32px 0;
}

.topRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  margin-bottom: 20px;
}

.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #B8CAD5;
}

.cardsContainer {
  display: flex;
  margin-top: 32px;
  padding: 0 32px;
  column-gap: 32px;
}

.card {
  display: flex;
  width: 333px;
  flex-direction: column;
}

.ico {
  width: 48px;
  height: 48px;
  margin-bottom: 28px;
}

.cardTitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 16px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 16px;
  white-space: pre-line;
}

.cardBtn {
  margin-left: -14px;
  color: var(--color-typo-link);
}
