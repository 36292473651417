.table * {
	box-sizing: border-box;
}

table:not([cellpadding]) .th, table:not([cellpadding]) .td {
	padding: 0;
 }

.table {
  border-spacing: 0;
  width: 100%;
}

thead {
	position: sticky;
	top: 0;
  z-index: 1;
 }

.th,
.th_placeholder
{
  position: relative;
  top: 0;
  border-right: 1px solid var(--color-bg-border, rgba(0, 65, 102, 0.20));
  background-color: var(--color-bg-default);
}

.th::after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	border-bottom: 1px solid var(--color-bg-border, rgba(0, 65, 102, 0.20));
	pointer-events: none;
	width: 100%;
 }

.td {
  border-right: 1px solid var(--color-bg-border, rgba(0, 65, 102, 0.20));
  border-bottom: 1px solid var(--color-bg-border, rgba(0, 65, 102, 0.20));
}

.tdRowHover {
  cursor: pointer !important;
}

.td:last-child {
  border-right :none;
}

.th:last-child {
  border-right :none;
}

.tbody {
  display: block;
}

.tr {
  display: block;
}

.trHover:hover,
.tr_highlighted
{
  background-color: var(--color-bg-stripe);
}

.innerRow {
  background-color: var(--color-bg-stripe);
}

.trHover:hover.innerRow {
  background: linear-gradient(0deg, rgba(0, 32, 51, 0.05), rgba(0, 32, 51, 0.05)),
  linear-gradient(0deg, rgba(0, 32, 51, 0.05), rgba(0, 32, 51, 0.05));
}

tbody > tr:last-child > .td {
  border-bottom: none;
}

.resizer {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 5px;
	cursor: col-resize;
}


