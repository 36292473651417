.container {
  display: flex;
  justify-content: center;
  scroll-margin-top: 60px;
  margin-top: 96px;
}

.contentContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.productCardList {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
