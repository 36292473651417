.container {
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-bottom: 4px;
}

.url {
  display: flex;
  cursor: pointer;
}

.ico {
  width: 12px;
  height: 12px;
  margin-left: 4px;
}
.url .title {
  color: var(--typo-link, #0078D2);
}

