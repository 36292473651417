

.tox-statusbar {
  display: none !important;
}

.custom-editor-error {
  .tox.tox-tinymce {
    border: 1px solid red;
    //border-radius: 4px !important;
  }
}
.tox .tox-edit-area::before {
  border: none !important;;
}

//
//.tox-tinymce {
//    border: 1px solid rgba(0, 66, 105, 0.28) !important;
//    border-radius: 4px !important;
//}
//
//.tox-editor-header {
//    z-index: 0 !important;
//    box-shadow: none !important;
//    border-bottom: 2px solid rgba(0, 66, 105, 0.28) !important;
//    padding: 12px 0 !important;
//}
//.tox .tox-tbtn__select-label {
//    font-family: "Inter";
//    font-weight: 700 !important;
//    font-size: 16px;
//    line-height: 24px;
//    color: #33617d;
//}
//.tox-toolbar__group {
//    button {
//        padding: 0 !important;
//        width: 32px !important;
//        height: 32px !important;
//        background: rgba(0, 66, 105, 0.07) !important;
//        border-radius: 4px !important;
//        margin: 0 12px 0 0 !important;
//        cursor: pointer !important;
//        span {
//            cursor: pointer !important;
//        }
//    }
//}

.tox-promotion {
  display: none !important;
}
