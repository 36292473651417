.container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.imgContainer {
  display: flex;
  width: 100%;
  height: 300px;
  margin-bottom: 40px;
  border-radius: 8px;
  overflow: hidden;
}

.rowInfo {
  display: flex;
  justify-content: space-between;
}

.type {
  color: var(--color-typo-secondary);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.tabsRow {
  display: flex;
  column-gap: 8px;
  margin-bottom: 32px;
}

.title {
  margin-bottom: 16px;
  white-space: pre-line;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
}

.description {
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.description p {
  margin: 0;
}

.rowBottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.btnReadMore {
  color: var(--color-typo-link, #0078D2);
  font-size: 14px;
  line-height: 150%;
  margin-left: -12px;
}

.time {
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.60));
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.delimiter {
  display: flex;
  margin-bottom: 40px;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 65, 102, 0.20);
}
