.container {
  display: flex;
  justify-content: center;
  margin-top: 144px;
  margin-bottom: 144px;
}

.contentContainer {
  display: flex;
  width: 1206px;
  padding: 48px 32px;
  flex-direction: column;
  scroll-margin-top: 60px;
  border-radius: 16px;
  background: var(--bg-secondary, #ECF1F4);
}

.title {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 48px */
  margin-bottom: 16px;
}

.subtitle {
  width: 649px;
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  white-space: pre-line;
  margin-bottom: 48px;
}

.scheme {
  display: flex;
  flex-wrap: wrap;
  column-gap: 13px;
  row-gap: 130px;
  padding-left: 36px;
  background-position-y:100px;

}
.schemeItem {
  width: 258px;
  cursor: pointer;
}
.itemTitle{
  margin-top: 16px;
  color: var(--typo-primary, #002033);
  /* M Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
.itemDesc {
  margin-top: 4px;
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
}
