.item {
  width: 349px;
  height: 156px;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid var(--bg-border, rgba(0, 65, 102, 0.2));
  background: var(--bg-default, #FFF);
  box-sizing: content-box;
  position: relative;
}
.item .header {
  display: flex;
  justify-content: space-between;
}
.item .header > div {
  display: flex;
  align-items: center;
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
}
.item .header > div img {
  margin-right: 4px;
  width: auto;
  height: 20px;
}
.item .title {
  margin-top: 16px;
  color: var(--typo-primary, #002033);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
}
.item .buttons {
  position: absolute;
  bottom: 20px;
  display: flex;
  align-items: center;
}
.item .edit {
  margin-left: 12px;
}
.item .blocks > * {
  margin-right: 8px;
}

.reportsModal {
  white-space: pre-line;
  padding: 32px 20px 20px;
}

.accessDesc {
  color: #002033;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  margin-bottom: 16px;
}
.closeButton {
  position: ABSOLUTE;
  right: 10px;
  top: 10px;
}

