.container {
  display: flex;
  width: 100vw;
  height: 445px;
  justify-content: center;
  margin-bottom: 192px;
}

.contentContainer {
  position: relative;
  display: flex;
  width: var(--contentMinWidth);
  flex-direction: column;
  white-space: pre-line;
}

.title {
  margin-top: 57px;
  margin-bottom: 72px;
  line-height: 87px;
}

.description {
  padding-left: 6px;
}

.buttonRow {
  display: flex;
  padding-right: 6px;
  margin-top: -20px;
  justify-content: flex-end;
  margin-bottom: 20px;
}
