.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.cardContainer {
  display: flex;
  width: 188px;
  flex-shrink: 0;
  justify-content: center;
  height: 16px;
  /*margin-right: 16px;*/
}

.centerVerticalLine {
  width: 1px;
  height: 100%;
  background-color: #002033;
}
