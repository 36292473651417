.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 144px;
}

.title {
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 600;
  line-height: 150%;
}

.description {
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  white-space: pre-line;
  margin-bottom: 48px;
}

.cardsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
}

.card{
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 8px;
  background: var(--color-bg-secondary, #ECF1F4);
}

.titleRow {
  display: flex;
  margin-bottom: 12px;
}

.cardTitle {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}

.cardDescription {
  display: flex;
  flex-grow: 1;
  height: 48px;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.notAvailable {
  color: rgba(0, 32, 51, 0.26);
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
}

.mySelect {
  width: 205px;
  :global .Select-Control {
    background: #00426912 !important;
    border: none;
  }
  :global .Select-Placeholder {
    color: #00395CCC !important;
  }
}
