.container {
  display: flex;
  height: 458px;
  justify-content: center;
  /*border-bottom: 1px solid var(--color-bg-border);*/
  background: var(--bg-secondary, #ECF1F4);
  padding-top: 40px;
  box-sizing: border-box;

}
.contentContainer {
  width: 1206px;
  background-size: contain;
}


.breadcrumbs {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  .breadcrumbsLink {
    color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    cursor: pointer;
  }

  .breadcrumbsCurrent {
    color: var(--typo-primary, #002033);
    /* M */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }

  .breadcrumbsArrow {
    margin: 0 12px;
  }
}

.productTitle {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 48px */
  width: 595px;
  margin-bottom: 16px;
}

.subtitle {
  color: var(--typo-primary, #002033);
  /* XL Regular */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  width: 595px;
  margin-bottom: 48px;
}

.heroButton {
  margin-top: 30px;
  margin-right: 24px;
}
