.container {
  display: flex;
  justify-content: center;
}

.contentContainer {
  display: flex;
  width: 1672px;
  padding: 0 36px 36px 36px;
  flex-direction: column;
}

.title {
  margin-bottom: 8px;
}
