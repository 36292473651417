.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.subTitle {
  font-size: 32px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 32px;
}

.teamList {
  display: flex;
  flex-wrap: wrap;
}
