.container {
  display: flex;
  width: 712px;
  padding: 20px;
  margin-bottom: 48px;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid var(--color-bg-border, rgba(0, 65, 102, 0.20));
}

.leftColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.textPart {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 8px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  white-space: pre-line;
}

.buttonsRow {
  display: flex;
  justify-content: space-between;
}
