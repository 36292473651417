.tox-statusbar {
  display: none !important;
}

.custom-editor-error .tox.tox-tinymce {
  border: 1px solid red;
}

.tox .tox-edit-area::before {
  border: none !important;
}

.tox-promotion {
  display: none !important;
}

