.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 44px;
  /*padding: 4px 8px;*/
  font-size: 12px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.12px;
  white-space: nowrap;
}

.withChildren {
  justify-content: space-between;
}

.children {
  display: flex;
}
