.reportsModal {
  width: 720px;
  .header {
    padding: 24px 32px;
    color: var(--typo-primary, #002033);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    border-bottom: 1px solid var(--bg-border, rgba(0, 65, 102, 0.20));
    display: flex;
    align-items: center;
  }
  .footer {
    padding: 24px 32px;
    border-top: 1px solid var(--bg-border, rgba(0, 65, 102, 0.20));
    text-align: right;
    > button {
      margin-left: 8px;
    }
  }
  .body {
    padding: 24px 32px;
    overflow: auto;
    height: 500px;

    .field {
      margin-bottom: 20px;
    }
    .TextField {
      width: 100%;
    }
    .label {
      color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      margin-bottom: 8px;
    }
  }
  .adGroups {
    color: var(--typo-primary, #002033);
    /* L Medium */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    margin: 24px 0 20px;
  }
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
}
