.container {
  display: flex;
  width: 290px;
}

.teacherAvatar {
  margin-right: 16px;
  flex-shrink: 0;
}

.teacherCardRightColumn {
  display: flex;
  flex-direction: column;
}

.teacherName {
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 4px;
}

.teacherPosition {
  font-size: 12px !important;
  font-weight: 500;
  line-height: 150% !important;
  margin-bottom: 8px;
}

.teacherEmail {
  font-size: 12px;
  line-height: 150%;
  color: var(--color-typo-secondary);
  cursor: pointer;
}
