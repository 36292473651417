.container {
    margin-top: 96px;
    display: flex;

}
.contentContainer {
    width: 1206px;
    margin: 0 auto;
}

.breadcrumbs {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .breadcrumbsLink {
        color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        cursor: pointer;
    }

    .breadcrumbsCurrent {
        color: var(--typo-primary, #002033);
        /* M */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
    }

    .breadcrumbsArrow {
        margin: 0 12px;
    }
}
.title {
    margin-top: 32px;
    color: var(--typo-primary, #002033);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
}

.formItem {
    margin: 32px 0;
    .formItemTitle {
        color: var(--typo-primary, #002033);
        /* L Medium */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 27px */
        margin-bottom: 24px;
    }
}
.fullWidth {
    // width: 697px !important;
    // margin-bottom: 20px;
}
.link {
    color: var(--typo-link, #0078D2);
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.photo {
    width: 72px;
    height: 72px;
}

.newsAction {
    display: flex;
    justify-content: space-between;
    > div {
        button {
            margin-left: 8px;
        }
    }
}

.attachment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    img {
        height: 50px;
    }
}

.speakerTitle {
    color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 20px;
}

.pointer {
    cursor: pointer;
}
