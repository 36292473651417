.container {
    margin-top: 96px;
    display: flex;

}
.contentContainer {
    width: 1206px;
    margin: 0 auto;
}


.menu {
    position: sticky;
    top: 100px;
}
.menuItem {
    padding: 8px 16px 8px 0;
    width: 234px;
    border-right: 2px solid var(--control-default-bg-border, rgba(0, 66, 105, 0.28));
    color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    cursor: pointer;

}
.menuItemActive {
    padding: 8px 16px 8px 0;
    width: 234px;
    color: var(--typo-primary, #002033);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    border-right: 2px solid var(--control-primary-bg, #0078D2);;
}


.content {
    margin-left: 306px;
    margin-top: -252px;
}


.metricTitle {
    display: flex;
    align-items: center;
    color: var(--typo-primary, #002033);
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 48px */
    margin-bottom: 48px;
}
.productOwner{
    color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-left: 15px;
    position: relative;
    top: 3px;
}
.productImg {
    margin-right: 16px;
}

.metricsContainer {
    margin-bottom: 120px;
    scroll-margin-block: 100px;
}
.metric {

    width: 100%;
    border-radius: 16px;
    border: 1px solid var(--bg-secondary, #ECF1F4);
    display: flex;
    overflow:hidden;
    margin-bottom: 24px;
}
.input {
    padding: 32px;
    width: 616px;
    box-sizing: border-box;
}
.metricName {
    color: var(--typo-primary, #002033);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    margin-bottom: 24px;
}
.textInput {
    width: calc(100% - 40px);
    margin-bottom: 8px;
}
.metricDesc {
    color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}

.currentValues {
    padding: 32px 32px 0 32px;
    background: var(--bg-secondary, #ECF1F4);
    width: 284px;
    box-sizing: border-box;
}
.currentNumber {
    color: var(--control-primary-bg, #0078D2);
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 48px */
}
.currentDesc {
    color: var(--typo-primary, #002033);
    /* M Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
}

.submitButton {
    position: relative;
    top: 6px;
    left: -2px;
}
