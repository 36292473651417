.container {
  display: flex;
  justify-content: center;
  margin-top: 144px;
  scroll-margin-top: 60px;
}

.contentContainer {
  position: relative;
  display: flex;
  width: 1366px;
  padding: 0 80px;
  flex-direction: column;
}

.title {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 48px */
  /*margin-bottom: 72px;*/
}
