.container {
  display: flex;
  flex-shrink: 0;
  height: 100%;
  overflow: hidden;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 4px);
  overflow: hidden;
}

.topRow {
  display: flex;
  max-width: 400px;
  padding: 12px 0;
}

.titleBlock {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.searchContainer {
  display: flex;
  width: 100%;
  max-width: 400px;
}

.title {
  display: flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  margin-right: 18px;
}

.titleShowAll {
  color: var(--color-typo-link, #0078D2);
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
}

.titleShowAllGray {
  margin-top: 2px;
  color: var(--color-typo-secondary);
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
}

.button {
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: var(--color-control-bg-ghost);
  border-radius: var(--control-radius);
  padding: 0 0 0 1px;
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #B8CAD5;
  margin-top: 12px;
}
