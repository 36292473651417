.container {
  display: flex;
  width: calc(100% + 8px);
  height: calc(100vh - 390px);
  flex-wrap: wrap;
  align-content: baseline;
  column-gap: 16px;
  row-gap: 20px;
  overflow-y: scroll;
  padding: 12px 20px;
  background: var(--color-bg-secondary, #ECF1F4);
  border-top: 1px solid var(--color-bg-border);
  border-bottom: 1px solid var(--color-bg-border);
}

.container::-webkit-scrollbar-track {
  background-color: inherit;
}
