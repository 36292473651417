.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.title {
  padding: 24px 20px;
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
  border-bottom: 1px solid var(--color-bg-border);
}
