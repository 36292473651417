.container {
  display: flex;
  margin-bottom: 20px;
  flex-grow: 1;
  align-items: center;
  padding: 5px 12px;
  border: 1px solid rgba(0, 66, 105, 0.28);
  border-radius: 4px;
}

.conditionsList {
  display: flex;
  flex-grow: 1;
  column-gap: 4px;
}

.tag {
  padding-left: 6px !important;
  height: 18px !important;
  font-size: 10px !important;
  align-items: center;
}

.btnCloseContainer {
  position: relative;
  display: flex;
}

.btnClose {
  padding: 0 !important;
  width: 24px !important;
  height: 24px !important;
  z-index: 1;
}

.ico {
  position: absolute;
  top: 6px;
  left: -6px;
  margin-left: 12px;
  width: 12px;
  height: 12px;
  color: rgba(0, 57, 92, 0.8);
  cursor: pointer;
}
