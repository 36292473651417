.container {
  display: flex;
  width: 100%;
  padding-bottom: 16px;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

.container::-webkit-scrollbar-track {
  background-color: inherit !important;
}

.notFound {
  margin-top: 16px;
  color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
