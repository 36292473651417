.container {
  display: flex;
  /*width: var(--contentMinWidth);*/
  justify-content: space-between;
  width: 100%;
}

.verticalContainer {
  display: flex;
  /*width: var(--widthCard);*/
}
