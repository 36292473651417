.container {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  width: 100%;
  padding: 32px;
  border-radius: 8px;
  background: var(--color-bg-secondary, #ECF1F4);
}

.dateTime {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 4px;
}

.day {
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.60));
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 16px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 42px;
}
