.breadcrumbs {
  margin-top: 24px;
  margin-bottom: 40px;
}

.columnContainer {
  display: flex;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-right: 112px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 40px;
}

.tags {
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 40px;
}

.subTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 12px;
}

.description {
  display: flex;
  font-size: 16px;
  line-height: 150%;
  white-space: pre-line;
  margin-bottom: 40px;
}

.row {
  display: flex;
  margin-bottom: 40px;
  column-gap: 8px;
}

.rowItem {
  font-size: 16px;
  line-height: 150%;
}

.place {
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 40px;
}

.provider {
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 40px;
}

.teacherCard {
  display: flex;
  width: 390px;
  margin-bottom: 40px;
}

.teacherAvatar {
  margin-right: 16px;
  flex-shrink: 0;
}

.teacherCardRightColumn {
  display: flex;
  flex-direction: column;
}

.teacherName {
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 4px;
}

.teacherPosition {
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 8px;
}

.teacherEmail {
  font-size: 12px;
  line-height: 150%;
  color: var(--color-typo-secondary);
  cursor: pointer;
}

.rightColumnSubTitle {
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 12px;
}

.buttonRow {
  display: flex;
  column-gap: 12px;
  margin-bottom: 24px;
}

.contactEmailContainer {
  display: flex;
  margin-bottom: 12px;
}

.contactEmail {
  font-size: 12px;
  color: var(--color-bg-link);
  cursor: pointer;
}

.loaderContainer {
  width: 100%;
  height: calc(100vh - 130px);
}
