.container {
  display: flex;
  margin-bottom: 12px;
  margin-right: 8px;
  gap: 12px;
  flex-grow: 1;
}

.choiceGroupContainer {
  display: flex;
  width: 345px;
  margin-right: 12px;
}
