.container {
  position: relative;
  display: flex;
  height: 180px;
  margin-bottom: 120px;
  background: linear-gradient(263.32deg, #0078D2 44.86%, rgba(0, 120, 210, 0.6) 81.48%);
  border-radius: 4px;
}

.bannerIco_1 {
  position: absolute;
  bottom: 60px;
  left: 180px;
}

.bannerIco_2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.column {
  display: flex;
  flex-direction: column;
  width: 432px;
  padding-top: 50px;
  margin-left: 386px;
}

.title {
  display: flex;
  width: 436px;
  flex-shrink: 0;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  white-space: pre-line;
  color: #FFFFFF;
}

.subTitle {
  display: flex;
  width: 436px;
  font-size: 16px;
  line-height: 150%;
  white-space: pre-line;
  color: #FFFFFF;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.button {
  display: flex;
  align-items: center;
  height: 48px;
  margin-right: 40px;
  margin-top: 66px;
  justify-content: center;
  padding: 0 24px;
  background: #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
  color: #0078D2;
  outline: none;
  border: none;
}

.ico {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.btnTitle {
  margin-top: 2px;
}
