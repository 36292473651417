.container {
  display: flex;
  justify-content: center;
  /*margin-bottom: 72px;*/
  scroll-margin-top: 60px;
  margin-top: 96px;
}

.contentContainer {
  position: relative;
  display: flex;
  width: 1366px;
  padding: 0 80px;
  flex-direction: column;
}

.infoRow {
  display: flex;
  flex-wrap: wrap;
  gap: 16px
}

.title {
  margin-bottom: 32px;
  font-weight: 700;
}

.subtitle {
  display: flex;
  width: 450px;
  margin-right: 20px;
  white-space: pre-line;
}

.cardsContainer {
  display: flex;
  /*flex-direction: column;*/
  row-gap: 32px;
}

.iconEdit {
  margin-left: 16px;
  cursor: pointer;
}
