.sidebarFilter {
  width: 452px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: var(--bg-secondary, #ECF1F4);
  row-gap: 12px;
  padding-bottom: 55px;
}

.sidebarTitle {
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  padding: 14px 12px;
  background: #fff;
}

.sidebarItem {
  background: #fff;
  padding: 24px;
}
.sidebarItem .itemTitle {
  color: rgba(0, 0, 0, 0.8);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
  padding-bottom: 20px;
  border-bottom: 1px solid #DEE4E8;
}
.sidebarItem .itemText {
  color: var(--control-default-typo, #002033);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.bottomButtons {
  background: #fff;
  border-top: 1px solid #C7D4DB;
  padding: 8px 12px;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.bottomButtons button {
  margin-left: 10px;
}

.clear {
  margin-left: 12px;
  color: var(--typo-link, #0078D2);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  cursor: pointer;
}

.checkboxGroup {
  margin-top: 12px;
  border-radius: 4px;
  border: 1px solid var(--control-default-bg-border, rgba(0, 66, 105, 0.28));
  background: var(--control-default-bg, #FFF);
  padding: 9px 12px;
  max-height: 157px;
  overflow: auto;
}

