.container {
  display: flex;
  width: 391px;
  flex-direction: column;
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 12px;
}
