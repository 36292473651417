.container, .container_withChildren {
  display: flex;
  padding: 12px;
}

.container:hover {
  background-color: #ECF1F4;
;
}

.title, .title_withChildren {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disabled {
  color: var(--color-control-typo-disable);
  cursor: not-allowed;
}

.disabled:hover {
  color: var(--color-control-typo-disable);
}

.delimiterContainer {
  display: flex;
  padding: 0 12px;
  flex-direction: column;
}

.titleDelimiter {
  margin-top: 16px;
  font-weight: 400;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: var(--color-typo-secondary);
  margin-bottom: 8px;
}

.delimiter {
  width: 100%;
  height: 1px;
  background-color: var(--color-control-bg-border-default);
}

.popover {
  background-color: white;
  cursor: pointer;
}

.arrowIcon {
  position: absolute;
  margin-right: 4px;
  right: 0;
}

.itemHighlighted {
  background-color: #0020330D;
}
