.container {
  display: flex;
  width: 100%;
  border: 1px solid #CCD9E0;
  border-radius: 4px;
  overflow: hidden;
}

.noBottomBorder {
  border-bottom: none;
}

.table {
  width: 100%;
  border-radius: 4px;
}

.red {
  background-color: red;
  width: 50%;
}

