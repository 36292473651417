.container {
  display: flex;
  column-gap: 32px;
  margin-bottom: 24px;
  flex-direction: row;
  justify-content: space-between;
}

.container > div {
  display: FLEX;
  column-gap: 16px;
}

.switch {
  display: flex;
  align-items: center;
}

.title {
  font-size: 14px;
  margin-right: 8px;
  color: var(--color-control-typo-ghost);
}

.active {
  color: rgb(0, 120, 210);
}

.addSubscribers {
  width: 704px;
  /*// padding: 20px;*/
}

.formTitle {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  padding: 20px 30px;
  border-bottom: 1px solid var(--bg-border, rgba(0, 65, 102, 0.2));
}

.formFooter {
  border-top: 1px solid var(--bg-border, rgba(0, 65, 102, 0.2));
  text-align: right;
  padding: 20px 30px;
}

.formContent {
  padding: 20px 30px;
}

.fullWidth {
  width: 100%;
  margin-bottom: 16px;
}

.attachment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.attachment img {
  height: 50px;
}

.formFooter button {
  margin-left: 8px;
}

.withDelete {
  display: flex;
  justify-content: space-between;
}

.remove {
  background: red !important;
}

