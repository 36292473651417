.container {
  display: flex;
  height: 458px;
  justify-content: center;
  /*border-bottom: 1px solid var(--color-bg-border);*/
  background: var(--bg-secondary, #ECF1F4);
}

.contentContainer {
  display: flex;
  width: 1366px;
  padding: 0 80px;
  flex-direction: column;
  background: url('../../../img/pageBanners/data-platform.png') right bottom no-repeat;
  background-size: contain;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  padding: 80px 0;
}

.title {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 48px */
  margin-bottom: 16px;
}

.subtitle {
  white-space: pre-line;
  color: var(--typo-primary, #002033);
  /* XL Regular */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  width: 595px;
}

.number {
  margin-top: 48px;
  color: var(--typo-brand, #0071B2);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */
}
.numberDesc {
  margin-top: 4px;
  white-space: pre-line;
  color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
  /* M Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
