.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 391px;
  height: 328px;
  flex-shrink: 0;
}

.title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 8px;
}

.description {
  margin-top: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
}

.ico {
  position: absolute;
  top: 60px;
  left: 146px;
  cursor: pointer;
  z-index: 1;
}

.iframe {
  height: 219px;
  outline: none;
  border: none;
  border-radius: 4px;
  pointer-events: none;
}
