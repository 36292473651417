.container {
  display: flex;
  border-radius: 16px;
  background: var(--color-bg-secondary, #ECF1F4);
  margin-bottom: 144px;
}


.rightColum {
  display: flex;
  padding: 72px 48px;
  flex-direction: column;
}

.title {
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 600;
  line-height: 150%;
}

.description {
  white-space: pre-line;
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 48px;
}

.btn {
  display: flex;
  justify-content: flex-end;
}
