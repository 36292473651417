.reportsModal {
  width: 720px;
}
.reportsModal .header {
  padding: 24px 32px;
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  border-bottom: 1px solid var(--bg-border, rgba(0, 65, 102, 0.2));
  display: flex;
  align-items: center;
}
.reportsModal .footer {
  padding: 24px 32px;
  border-top: 1px solid var(--bg-border, rgba(0, 65, 102, 0.2));
  text-align: right;
}
.reportsModal .footer > button {
  margin-left: 8px;
}
.reportsModal .body {
  padding: 24px 32px;
  overflow: auto;
  height: 500px;
}
.reportsModal .body .field {
  margin-bottom: 20px;
}
.reportsModal .body .TextField {
  width: 100%;
}
.reportsModal .body .label {
  color: var(--typo-secondary, rgba(0, 32, 51, 0.6));
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-bottom: 8px;
}
.reportsModal .adGroups {
  color: var(--typo-primary, #002033);
  /* L Medium */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  margin: 24px 0 20px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

