.container {
  display: flex;
  flex-direction: column;
  width: 369px;
  /*height: 372px;*/
  padding: 40px 32px;
  border-radius: 8px;
  border: 1px solid var(--bg-border, rgba(0, 65, 102, 0.20));
  background: var(--bg-default, #FFF);
}

.firstRow {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.blueIndexBox {
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  justify-content: center;
  align-items: center;
  background: #32B4FF;
  border-radius: 8px;
  overflow: hidden;
}

.indTitle {
  color: var(--color-bg-default);
}

.nameTitle {
  color: var(--typo-primary, #002033);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  margin: 24px 0;
}

.ownerTitle {
  color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}

.performanceRow {
  display: flex;
  justify-content: space-between;
}


.icon {
  width: 48px;
  height: 48px;
}

.footer {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
