.container {
  display: flex;
  justify-content: center;
  margin-top: 96px;
  scroll-margin-top: 60px;
}

.contentContainer {
  display: flex;
  width: 1270px;
  padding:48px 32px;
  flex-direction: column;
  border-radius: 16px;
  background: var(--bg-secondary, #ECF1F4);
}

.title {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 48px */
}

.componentsList {
  display: flex;
  flex-direction: column;
}

.thead {
  margin-top: 24px;
  color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  padding-bottom: 8px;
  display: flex;
}

.thProduct {
  width: 553px;
  margin-right: 16px;
}
.thCurrent, .thFuture {
  width: 270px;
}
.thCurrent {
  margin-right: 16px;
}
