.container {
  display: flex;
  flex-direction: column;
  width: 392px;
  flex-shrink: 0;
}

.badgesContainer {
  display: flex;
  height: 16px;
  margin-bottom: 8px;
  column-gap: 4px;
}

.title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 8px;
}

.description {
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 4px;
  overflow: hidden;
}

.tags {
  height: 21px;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 20px;
  overflow: hidden;
}

.buttonRow {
  display: flex;
  column-gap: 20px;
}
