.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 12px 0 12px 20px;
}

.containerWithoutLeftPadding {
  padding-left: 0;
}

.buttonCatalogList {
  margin-right: 8px;
}

.row {
  display: flex;
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 12px;
}

