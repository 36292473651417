.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
}

.delimiterWrapper {
  margin-top: -1px;
  margin-bottom: 60px;
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #B8CAD5;
}

.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  margin-bottom: 20px;
}

.filtersRow {
  display: flex;
  padding-left: 6px;
}

.allTag {
  margin-right: 24px;
}

.tagGroup {
  display: flex;
  column-gap: 12px;
  margin-bottom: 20px;
}

.btnSeeAll {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
