.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 48px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 32px;
}

.speakersContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.row {
  display: flex;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  object-fit: contain;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.name {
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
}

.position {
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.60));
  font-size: 16px;
  line-height: 150%;
  white-space: pre-line;
}
