.container {
  display: flex;
  flex-direction: column;
  width: 391px;
}

.top {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.bottomBtnContainer {
  display: flex;
  justify-content: flex-end;
}
