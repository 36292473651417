.container {
    display: flex;
    height: 100%;
    width: 188px;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    /*margin-right: 16px;*/
}

.allHeightCentralLine {
    display: flex;
    flex-shrink: 0;
    width: 1px;
    height: 100%;
    background-color: #002033;

}

.emptyCardContainer {
    height: 100%;
}

.centerVerticalLine {
    width: 1px;
    height: 100%;
    background-color: #002033;
}

.teamAdd {
    padding: 8px;
    background: #fff;
    border-radius: var(--consta-spaces-2xs, 4px);
    background: var(--bg-default, #FFF);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    margin-top: 8px;
    width: 174px;
    box-sizing: border-box;
}

.teamAdd button {
    width: 124px;

}

.teamAdd img {
    cursor: pointer;
}
