.emptyDocumentsContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.emptyDocumentsTitle {
  width: 270px;
}

.title {
  display: inline;
}

.continueTitle {
  display: inline;
  cursor: pointer;
}
