.container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.noPagination {
  height: 32px;
}

.leftPart {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.textField {
  width: 50px;
}

.amountTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-typo-secondary);
  min-width: 28px;
}

.btnTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
}

.rightPart {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.allAmountTitle {
  margin-right: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-typo-secondary);
  white-space: nowrap;
}

.amountTitleWithCustomButton {
  color: var(--color-typo-link);
}

.showFor {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-typo-secondary);
  white-space: nowrap;
}

.select {
  min-width: 75px;
}
