.container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: var(--color-bg-default);
  padding: 15px;
  border: 1px solid var(--color-bg-default);
  overflow: hidden;
  max-width: 1170px;
  flex-wrap: wrap;
}

.active {
  border: 1px solid var(--color-typo-link);
}

.leaderBadge {
  position: absolute;
  top: 0;
  left: 0;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.domainTitle {
  margin-bottom: 8px;
  font-size: 10px;
  font-weight: 400;
  line-height: 140%;
  color: var(--color-typo-secondary);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row {
  display: flex;
  flex-shrink: 0;
  width: 249px;
  padding-right: 16px;
}

.domainChiefName {
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin-right: 8px;
}

.rightColumn {
  display: flex;
  flex-direction: column;
}

.domainChiefEmail {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--color-typo-secondary);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.confluenceUri {
  margin-top: 8px;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 400;
  line-height: 140%;
  color: var(--color-typo-link);
  gap: 6px;
  cursor: pointer;
}

.personsList {
  display: flex;
  column-gap: 48px;
  flex-wrap: wrap;
  row-gap: 24px;
  width:101%;
}
