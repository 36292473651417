.container {
  display: flex;
  flex-direction: column;
  width: 172px;
  max-height: 139px;
  min-height: 124px;
  padding: 7px;
  background-color: var(--color-bg-default);
  border-radius: 4px;
  border: 1px solid var(--color-bg-default);
}

.active {
  border: 1px solid var(--color-typo-link);
}

.topRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;
}

.avatar {
  flex-shrink: 0;
}

.colorCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
}

.textContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
}

.userName {
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 4px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.userPosition {
  height: 28px;
  font-size: 10px;
  font-weight: 400;
  line-height: 140%;
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.60));
  margin-bottom: 8px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.userEmail {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: var(--color-typo-link, #0078D2);
  font-size: 10px;
  font-weight: 400;
  line-height: 140%;
  gap: 6px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}


















/*.emptyContainer {*/
/*  background-color: inherit;*/
/*  box-shadow: none;*/
/*  cursor: default;*/
/*}*/

/*.teacherAvatar {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 48px;*/
/*  height: 48px;*/
/*  flex-shrink: 0;*/
/*  font-size: 14px;*/
/*}*/

/*.textContainer {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/
