.container {
  display: flex;
  margin-bottom: 12px;
  border-radius: 4px;
  border: 1px solid var(--color-bg-border);
  background: var(--color-bg-default);
  padding: 5px 12px;
  column-gap: 4px;
}

.tag {
  padding-left: 6px !important;
  height: 18px !important;
  font-size: 10px !important;
  align-items: center;
}
