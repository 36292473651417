.container {
  display: flex;
  width: 100%;
  align-items: center;
  height: 32px;
  min-width: 251px;
  padding: 0 12px;
  border: 1px solid rgba(0, 66, 105, 0.28);
  border-radius: 4px;
}

.input {
  font-size: 14px;
  border: none;
  outline: none;
}

.input::placeholder {
  color: rgba(0, 32, 51, 0.35);
}

.ico {
  margin-right: 12px;
  width: 16px;
  height: 16px;
  color: rgba(0, 32, 51, 0.35);
}
