.container {
  display: flex;
  width: 100%;
  /*padding: 16px 0;*/
  flex-direction: column;
  scroll-margin-top: 76px;
  /*overflow-y: scroll;*/
  height: calc(100vh - 60px);
  background-color: #FFFFFF;
}

.container::-webkit-scrollbar-track {
  background-color: var(--color-bg-default);
}
