.container {
  width: 143px;
  max-height: 66px;
}

.img {
  width: 100%;
  height: 100%;
  /*object-fit: cover;*/
}

