.container {
  display: flex;
  flex-direction: column;
  width: 275px;
  min-width: 275px;
  margin-right: 48px;
}

.description {
  display: flex;
  white-space: pre-line;
}
