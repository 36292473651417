.container {
  display: flex;
  margin-top: 60px;
  width: 100%;
  height: 332px;
  justify-content: center;
  background-color: var(--color-bg-secondary);
  flex-shrink: 0;
}

.content {
  display: flex;
  width: 1366px;
  padding: 0 80px;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  width: 895px;
  flex-shrink: 0;
}

.title {
  margin-top: 80px;
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 600;
  line-height: 150%;
}

.description {
  margin-bottom: 48px;
  white-space: pre-line;
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
}

.textContainer {
  display: flex;
  width: 100%;
  background-color: #09b37b;
}

.bannerImg {
  width: 391px;
  height: 332px;
}
