.container {
  display: flex;
  flex-direction: column;
}

.row {
  position: relative;
  display: flex;
  height: 40px;
  align-items: center;
  cursor: pointer;
}
.row.active {
  background: var(--control-ghost-bg, rgba(0, 66, 105, 0.07));
}

.title {
  color: var(--control-ghost-typo, rgba(0, 57, 92, 0.80));
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

/*.activeTitle {*/
/*  background: var(--control-ghost-bg, rgba(0, 66, 105, 0.07));*/
/*}*/

/*.ico {*/
/*  margin-top: 18px;*/
/*  margin-left: 4px;*/
/*}*/

.rotateIco {
  transform: rotate(180deg);
}

.activeSign {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 2px;
  height: 100%;
  background-color: var(--color-typo-link);
}

.icoContainer {
  display: flex;
  /*width: 32px;*/
  justify-content: flex-end;
  align-items: center;
  margin-left: 20px;
  margin-right: 8px;
}

.iconRight {
  position: absolute;
  right: 20px;
}

.submenu {
  position: absolute;
  width: 482px;
  left: -482px;
  z-index: -1;
  top: 0;
  box-sizing: border-box;
  padding: 32px 60px 0;
  height: calc(100vh - 60px);
  box-shadow: 0 8px 24px 0 rgba(0, 32, 51, 0.12), 0 4px 4px 0 rgba(0, 32, 51, 0.04);
  background: #fff;
  overflow-y: auto;
  transition: left 0.6s ease;
}
.active.row + .submenu {
  left: 315px;
}
.submenuTitle {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin-bottom: 24px;
}
