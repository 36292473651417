.container {
  display: flex;
  flex-direction: column;
  width: 1050px;
  min-height: 105px;
  padding: 32px 20px 20px 20px;
}

.container svg {
  margin-bottom: 32px;
}
.title {
  margin-bottom: 16px;
}

.description {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  margin-top: 32px;
  white-space: pre-line;
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #B8CAD5;
}

.sectionsGroup {
  display: flex;
  margin-top: 24px;
  column-gap: 16px;
}

.buttonContainer {
  display: flex;
  margin-top: 24px;
}
