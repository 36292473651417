.container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
}

.groupsList {
  display: flex;
  flex-direction: column;
  padding: 24px 16px 0 16px;
}

.sectionWrapper {
  display: flex;
  margin-bottom: 24px;
  z-index: 1;
}

.sectionWrapper + .sectionWrapper{
  border-top: 1px solid #97B2C4;
  padding-top: 24px;
}

.sectionTitle {
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.60));
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  margin-top: -8px;
  margin-bottom: 16px;
}

.editBlock {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-radius: var(--consta-spaces-2xs, 4px);
  background: var(--bg-default, #FFF);
  width: 1170px;
  margin-bottom: 16px;
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
}
