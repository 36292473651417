.container {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 20px;
  height: 82px;
  border-bottom: 1px solid var(--color-bg-border);
}

.title {
  display: flex;
  flex-grow: 1;
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
}

.linksGroup {
  display: flex;
  justify-content: center;
  column-gap: 16px;
}

.linkContainer {
  display: flex;
  cursor: pointer;
}

.linkTitle {
  display: flex;
  justify-content: center;
}

.iconOpenInNew {
  margin-top: 4px;
  width: 12px;
  height: 12px;
  margin-left: 8px;
}

.iconDownload {
  margin-top: 5px;
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
