.container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.delimiter {
  display: flex;
  margin-top: 24px;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 65, 102, 0.20);
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
