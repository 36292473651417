.contentContainer {
  position: sticky;
  top: 60px;
  display: flex;
  width: var(--contentMinWidth);
  padding-bottom: 32px;
  flex-direction: column;
  background-color: #FFFFFF;
  z-index: 50;
}

.choiceGroupWrapper {
  display: flex;
  width: 100%;
  background-color: #FFFFFF;
  padding-top: 32px;
}

.searchWrapper {
  display: flex;
  width: 100%;
  min-width: 180px;
  justify-content: flex-end;
  margin-left: 16px;
}
