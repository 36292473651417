.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.footer {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  padding: 0 20px;
}

.btnSubscribeContainer {
  display: flex;
  flex-grow: 1;
}

.btnSubscribe {
  color: var(--color-bg-link);
}

.btnGroup {
  display: flex;
  column-gap: 24px;
}

.btnCornerClose {
  position: absolute;
  top: 20px;
  right: 20px;
}
