.container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.notFound {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
}
