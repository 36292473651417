.breadcrumbs {
  margin-top: 24px;
  margin-bottom: 40px;
}

.columnContainer {
  display: flex;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-right: 112px;
}

.badgesContainer {
  display: flex;
  height: 16px;
  margin-bottom: 8px;
  column-gap: 4px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 8px;
}

.tags {
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 40px;
}

.subTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 12px;
}

.description {
  display: flex;
  font-size: 16px;
  line-height: 150%;
  white-space: pre-line;
  margin-bottom: 40px;
  color: var(--color-typo-secondary);
}

.detailsGroup {
  margin-bottom: 40px;
}

.row {
  display: flex;
  margin-bottom: 8px;
}

.leftPartRow {
  width: 180px;
  margin-right: 8px;
  font-size: 16px;
  line-height: 120%;
  color: var(--color-typo-secondary);
}

.rightPartRow {
  font-size: 16px;
  line-height: 120%;
}

.rightColumnSubTitle {
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 12px;
}

.buttonRow {
  display: flex;
  column-gap: 12px;
  margin-bottom: 24px;
}

.contactEmailContainer {
  display: flex;
  margin-bottom: 12px;
}

.contactEmail {
  font-size: 12px;
  color: var(--color-bg-link);
  cursor: pointer;
}

.loaderContainer {
  width: 100%;
  height: calc(100vh - 130px);
}

.teachersList {
  display: flex;
  flex-wrap: wrap;
}

.responsibleList {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
