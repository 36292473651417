.container {
  display: flex;
  justify-content: center;
  /*margin-bottom: 72px;*/
  scroll-margin-top: 60px;
  margin-top: 96px;
}

.contentContainer {
  position: relative;
  display: flex;
  width: 1366px;
  padding: 0 80px;
  flex-direction: column;
}

.title {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 48px */
  width: 595px;
  margin-bottom: 40px;
}

.scheme {
  display: flex;
  justify-content: space-between;
}

.scheme .leftSide {
  display: flex;
  flex-direction: column;
  row-gap: 21px;
}

.scheme .leftSide > div {
  width: 298px;
  height: 179px;
  padding: 12px 14px;
  border-radius: 8px;
  border: 1px dashed var(--bg-brand, #0071B2);
  display: flex;
}

.scheme .leftSide > div .icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 120px;
  margin-right: 8px;
}

.scheme .leftSide > div .icon .img {
  margin-bottom: 8px;
  margin-top: 14px;
  width: 64px;
  height: 64px;
}

.iconName {
  color: #0970BB;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 16.8px */
  text-transform: uppercase;
}

.iconDesc {
  color: #0970BB;
  text-align: center;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 11.2px */
  text-transform: uppercase;
}

.level {
  color: var(--typo-ghost, rgba(0, 32, 51, 0.3));
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 18px */
}

.levelName {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  text-transform: uppercase;
}

.rect {
  width: 62px;
  height: 4px;
  border-radius: 18px;
  background: #7ED5FD;
  margin-bottom: 8px;
}

.rightSide {
  padding: 16px;
  border-radius: 8px;
  border: 1px dashed var(--bg-brand, #0071B2);
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.rightUl {
  margin: 0;
  padding-left: 24px;
  width: 286px;
  margin-top: 10px;
}
.rightUl li {
  margin-bottom: 5px;
}

.center {
  margin-top: 16px;
  width: 506px;
  height: 332px;
  position: relative;
}

.centerLevel {
  position: absolute;
}
.centerLevel .centerTitle {
  color: #4997DE;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
}
.centerLevel .centerDesc {
  margin-top: 2px;
  color: #000;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 15px */
  width: 215px;
}

