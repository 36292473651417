.container {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.filterBar {
  margin-bottom: 12px;
}

.textField {
  margin-bottom: 16px;
}
