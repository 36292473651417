.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 72px;
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  /*white-space: pre-line;*/
  margin-bottom: 16px;
}

.text img {
  width: 100%;
}

.textQuote {
  margin: 24px 0 24px 32px;
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
}

.textQuoteAuthor {
  margin-left: 32px;
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 4px;
}

.textQuotePosition {
  margin-left: 32px;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: var(--color-typo-secondary);
  margin-bottom: 40px;
}
.text p {
  margin: 0;
}
