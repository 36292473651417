.container {
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  max-height: calc(100vh - 285px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.container::-webkit-scrollbar-track {
  background-color: var(--color-bg-default)
}
