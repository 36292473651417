.container {
  display: flex;
  margin-bottom: 144px;
  padding: 32px;
  border-radius: 16px;
  background: var(--color-bg-secondary, #ECF1F4);
}

.title {
  width: 100%;
  margin-left: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  white-space: pre-line;
}
