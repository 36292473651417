.container {
  display: flex;
  width: 188px;
  flex-shrink: 0;
  align-items: center;
  flex-direction: column;
}

.centerVerticalLine {
  width: 1px;
  height: 8px;
  background-color: #002033;
}
