.container {
  display: flex;
  flex-direction: column;
  width: 360px;
  min-height: 120px;
}

.avatar {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.teamCard {
  width: 390px !important;
  min-height: 80px !important;
}

.groupTitle {
  margin-bottom: 16px;
  height: 30px;
}

.mainPartContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.teacherAvatar {
  margin-right: 16px;
  flex-shrink: 0;
}

.infoContainer {
  display: flex;
  max-width: 380px;
  flex-direction: column;
  white-space: pre-line;
}

.infoContainerTeamCard {
  max-width: 380px;
}

.nameInfo {
  margin-bottom: 4px;
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
}

.positionInfo {
  margin-bottom: 8px;
}

.positionTwoRow {
  height: 42px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.departmentInfo {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-bottom: 8px;

}

.departmentInfoTeamCard {
  -webkit-line-clamp: 2 !important;
  margin-bottom: 8px;
}

.linkToEmail {
  font-size: var(--size-text-s);
  color: var(--color-typo-secondary);
  cursor: pointer;

}
