.container {
  display: flex;
  width: 528px;
  flex-direction: column;
  position: relative;
  padding: 32px 20px 20px 20px;
  border-radius: 8px;
  background: var(--color-bg-default, #FFF);
  box-shadow: 0 12px 28px 0 rgba(0, 32, 51, 0.12), 0 8px 8px 0 rgba(0, 32, 51, 0.04);
}

.btnCornerClose {
  position: absolute;
  top: 20px;
  right: 20px;
}

.title {
  display: flex;
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 12px;
}

.description {
  display: flex;
  width: 372px;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 24px;
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #B8CAD5;
  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
