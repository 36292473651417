.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 144px;
}

.title {
    font-size: 32px;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 16px;
}

.description {
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
    white-space: pre-line;
    margin-bottom: 48px;
}

.cardsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 20px;
    cursor: pointer;
}

.cardTitle {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 150%;
    white-space: pre-line;
    color: #FFF;
}

.cardDescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    white-space: pre-line;
    color: #FFF;
}

.cardSearch {
    display: flex;
    flex-direction: column;
    width: 595px;
    height: 196px;
    padding: 40px;
    flex-shrink: 0;
    border-radius: 8px;
    background: url('../../../img/mainPage/searchBanner.png');
    cursor: pointer;
}

.cardLab {
    display: flex;
    flex-direction: column;
    width: 595px;
    height: 196px;
    padding: 40px;
    flex-shrink: 0;
    border-radius: 8px;
    background: url('../../../img/mainPage/labBanner.png');
    cursor: pointer;
    /*cursor: not-allowed;*/
}

.cardMeta {
    display: flex;
    flex-direction: column;
    width: 595px;
    height: 196px;
    padding: 40px;
    flex-shrink: 0;
    border-radius: 8px;
    background: url('../../../img/mainPage/metaBanner.png');
    cursor: pointer;
}

.cardBi {
    display: flex;
    flex-direction: column;
    width: 595px;
    height: 196px;
    padding: 40px;
    flex-shrink: 0;
    border-radius: 8px;
    background: url('../../../img/mainPage/biBanner.png');
    /*cursor: not-allowed;*/
}


.mySelect {
    width: 120px;

:global .Select-Control {
    /*background: #CCDAE0;*/
    border: none;
}

:global .Select-Placeholder {
}
}

.selects {
    display: flex;
    margin-top: 16px;
}
.selects > div {
    margin-right: 8px;
}
