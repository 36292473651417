.container {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.wrapper {
  height: 283px;
  width: 100%;
}

.prevBtn {
  margin-right: 13px;
}

.nextBtn {
  margin-left: 3px;
}


.slide {
  display: flex;
  justify-content: center;
}

.slideWrapper {
  display: flex;
  width: 391px;
  flex-direction: column;
  border: 1px solid blue;
}
