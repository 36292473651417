.container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 20px;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.button {
  color: var(--color-typo-link)
}
