.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 20px 12px 20px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 16px;
}
