.container {
  display: flex;
  column-gap: 8px;
}


.formTitle {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  padding: 20px 30px;
  border-bottom: 1px solid var(--bg-border, rgba(0, 65, 102, 0.20));
}

.formFooter {
  border-top: 1px solid var(--bg-border, rgba(0, 65, 102, 0.20));
  text-align: right;
  padding: 20px 30px;
}

.formContent {
  padding: 20px 30px;
}

.fullWidth {
  width: 100%;
  margin-bottom: 16px;
}

.attachment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.attachment img {
  height: 50px;
}
.formFooter button {
  margin-left: 8px;
}

.addSubscribers {
  width: 900px;
  /*// padding: 20px;*/
}

.col2 {
  display: flex;
  flex-wrap: wrap;
}
.col2 .item {
  width: 50%;
}

.itemTitle {
  color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-bottom: 16px;
}
.desc {
  color: var(--control-default-typo, #002033);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-bottom: 16px;
}
.author {
  display: flex;
  align-items: center;
  padding: 12px 4px;
  border-radius: 99px;
  background: var(--control-ghost-bg, rgba(0, 66, 105, 0.07));
  width: 300px;
}
.authorName {
  color: var(--typo-primary, #002033);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  margin-bottom: 3px;
}
.avatar{
  margin-right: 12px;
}
.authorEmail {
  color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
