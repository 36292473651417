.container {
  display: flex;
  flex-direction: column;
  width: 720px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0 12px 28px 0 rgba(0, 32, 51, 0.12), 0 8px 8px 0 rgba(0, 32, 51, 0.04);
}

.title {
  display: flex;
  padding: 0 32px;
  height: 70px;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: var(--color-bg-border)
}

.choiceGroupType {
  margin: 21px 32px 32px 32px;
}

.role {
  padding: 0 32px;
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
}

.choiceGroupRole {
  margin: 24px 0 32px 32px;
  width: 316px;
}

.suggestionTitle {
  margin: 24px 0 8px 32px;
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.60));
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;

}

.sign {
  color: var(--color-typo-alert, #EB3333);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.textFieldContainer {
  display: flex;
  height: 133px;
  width: calc(100% - 64px);
  margin: 0 32px 18px 32px;
}

.infoBlock {
  display: flex;
  margin: 0 32px 20px 32px;
  padding: 16px;
  border-radius: 4px;
  background: var(--color-bg-stripe, rgba(0, 32, 51, 0.05));
}

.infoText {
  white-space: pre-line;
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.60));
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.buttonsRow {
  display: flex;
  margin: 24px 32px;
  justify-content: flex-end;
  gap: 8px
}

.containerLoader {
  display: flex;
  flex-direction: column;
  width: 720px;
  border-radius: 4px;
  background: #FFF;
}
