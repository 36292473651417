.container {
  display: flex;
  width: 100%;
  max-height: 261px;
  margin-bottom: 16px;
  padding: 12px;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid var(--color-control-bg-border-default);
  overflow-y: scroll;
  row-gap: 12px;
}

.container::-webkit-scrollbar-track {
  background-color: var(--color-bg-default);
  border-radius: 4px;
}
