.container {
  width: calc(100vw - 486px);
  transition: width 0.3s ease;
  overflow: hidden;
}

.wildContainer {
  width: calc(100vw - 40px);
  transition: width 0.3s ease;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 334px);
  overflow: auto;
  border-radius: 4px;
  border: 1px solid var(--color-bg-border, rgba(0, 65, 102, 0.20));
  padding-bottom: 8px;
}

.loaderContainer {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.noDataPlaceHolder {
  width: 100%;
  display: flex;
  margin-top: 8px;
  color: var(--color-typo-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  transition: width 0.3s ease;
  background-color: #09b37b;
}

.noData {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  color: var(--color-typo-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.pagination {
  margin-top: 12px;
}



