.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 20px;
  max-width: 460px;
  min-width: 460px;
  min-height: 240px;
  background: inherit;
  border: 1px solid #B8CAD5;
  border-radius: 4px;
  background-color: var(--color-bg-default);
}

.row {
  display: flex;
  margin-bottom: 16px;
}

.documentTitle {
  display: flex;
  flex-shrink: 0;
  margin-bottom: 4px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
}

.organisationTitle {
  color: #667985;
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
}

.tagsListWrapper {
  display: flex;
  height: 100%;
  align-items: flex-end;
  margin-bottom: 24px;
}

.buttonRow {
  display: flex;
}

.btnGroup {
  display: flex;
  width: 100%;
  gap: 12px;
}

.buttonDownLoad {
  font-size: 12px;
}

.buttonInfo {
  font-size: 12px;
  color: #33617D;
}

.titleStatus {
  flex-grow: 1;
  text-align: end;
  color: #667985;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.toolTip {
  margin-left: -53px;
  display: flex;
  white-space: pre-line;
}

.btnInfo {
  display: flex;
  width: 32px;
  height: 32px;
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--color-bg-default);
  border-radius: 4px;
}

.btnInfo:hover {
  background-color: var(--color-control-bg-ghost-hover);
}
