.container {
  display: flex;
  flex-direction: column;
  width: 1050px;
  padding: 32px 20px 20px 20px;
  background: inherit;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
}

.wrapper {
  display: flex;
}
.breadCrumbs {
  margin-bottom: 12px;
}

.documentTitle {
  margin-bottom: 26px;
}

.row {
  display: flex;
  column-gap: 32px;
  /*align-items: center;*/
  margin-bottom: 17px;
}

.element {
  display: flex;
}

.titleStatus {
  margin-right: 8px;
}

.icoStatus {
  margin-top: 2px;
  margin-right: 6px;
}

.icoFormat {
  width: 12px;
  margin-top: 3px;
  margin-right: 6px;
}

.elementCopyLink {
  display: flex;
}

.icoConnection {
  width: 12px;
  margin-top: 3px;
  margin-right: 6px;
}

.titleCopyLink {
  color: var(--color-typo-link);
  cursor: pointer;
}

.tagsListWrapper {
  display: flex;
  min-height: 20px;
  align-items: flex-end;
  margin-bottom: 16px;
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #B8CAD5;
}

.rowInfoOne {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  column-gap: 16px;
  row-gap: 24px;
}

.subSection {
  display: flex;
  width: 327px;
  flex-direction: column;
}

.businessDescription {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
}

.subSectionTitle {
  margin-bottom: 8px;
}

.subSectionDescription {
  line-height: 140%;
}

.marginBottom {
  margin-bottom: 4px;
}

.paddingLeft {
  padding-left: 10px;
}



.splitRow {
  display: flex;
  width: 100%;
  column-gap: 16px;
}

.splitRowPart {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.sectionTitle {
  margin-bottom: 8px;
}

.titleName {
  margin-bottom: 4px;
}

.titlePosition {
  width: 80%;
  margin-bottom: 4px;
  color: var(--color-typo-secondary);
  line-height: 150%;
}

.linkToEmail {
  font-size: 12px;
  margin-bottom: 4px;
  color: var(--color-typo-secondary);
}

.row {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.description {
  display: flex;
  white-space: pre-line;
}

.processPictureContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #0078d2;
  max-height: 200px;
}

.img {
  display: flex;
  justify-content: center;
  object-fit: contain;
}
