.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  min-width: 1366px;
  min-height: calc(100vh - 60px - 130px);
}

.contentContainer {
  display: flex;
  width: var(--contentMinWidth);
  flex-direction: column;
}
